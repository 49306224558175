import { graphql } from 'gatsby'
import React, { ReactElement, useState } from 'react'
import CareerListBanner from '../../assets/images/career-list-banner.png'
import Seo from '../../components/Seo/Seo'
import './CareerList.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const Modal = loadable(() => import('../../components/Modal/Modal'))

type CareerListPropTypes = {
  data: {
    allLink: {
      nodes: {
        id: string
        link: string
        name: string
      }[]
    }
  }
}

const CareerList = ({
  data: { allLink },
}: CareerListPropTypes): ReactElement => {
  const [isLocationDetailsShowing, setIsLocationDetailsShowing] = useState<
    boolean
  >(false)
  const [careers] = useState(
    /* allLink.filter(
          (prop, index, self) =>
            index === self.findIndex((t) => t.name === prop.name),
        ), */
    allLink.nodes,
  )

  const displayCareer = careers.slice(0, 4)

  return (
    <Layout>
      <Seo
        title="Careers | Why Join Amaia Now | Amaia Land"
        jsonData={{
          keywords: 'career list, amaia, opportunities',
        }}
      />
      <div className="career-list-container">
        <div className="career-list">
          <h2 className="career-list-title">Job Openings</h2>
          <h3 className="career-list-title-secondary">Join our team!</h3>
          <p className="career-list-description">
            Amaia Land Corp. prides itself for being one of the top-of-mind real
            estate employers in the country today. With more than 500 corporate
            employees and close to 2,000 sales force spread across different
            geographical locations within the country, we have grown
            exponentially over the last 11 years, both in number of quality
            projects, as well as happy and engaged employees.
          </p>
          <p className="career-list-description">
            Anchored on Ayala Land’s core leadership values of integrity,
            reliability, and commitment, Amaia upholds the highest standards of
            people development. We are committed to ensure that our team members
            are provided with the best training and development opportunities
            that will allow them to achieve not only financial fulfillment but
            career development as well.
          </p>
          <p className="career-list-description">
            At Amaia, we don’t just build homes, we build careers too.
          </p>
          <ul className="career-list-item-container">
            {displayCareer.map((career) => (
              <li key={career.name} className="career-list-item">
                <a href={career.link} target="_blank" rel="noopener noreferrer">
                  {career.name}
                </a>
              </li>
            ))}
          </ul>
          {careers.length >= 4 ? (
            <button
              type="button"
              className="career-list-view-more"
              onClick={(): void => setIsLocationDetailsShowing(true)}
            >
              View More
            </button>
          ) : null}
        </div>
        <div className="career-list-banner">
          <LazyLoadImage src={CareerListBanner} alt="" />
        </div>
      </div>
      <Modal
        className="left"
        side
        noIcons
        isShowing={isLocationDetailsShowing}
        onClose={(): void => setIsLocationDetailsShowing(false)}
      >
        <div className="modal-career-list">
          <h4>Job Openings</h4>
          <ul className="modal-career-list-all">
            {careers.map((career) => (
              <li key={career.name} className="career-list-item">
                <a href={career.link} target="_blank" rel="noopener noreferrer">
                  {career.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </Layout>
  )
}

export default CareerList

export const pageQuery = graphql`
  query {
    allLink {
      nodes {
        ...LinkFields
      }
    }
  }
`
